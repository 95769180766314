import { addQueriesParam } from '@canalplus/mycanal-commons';
import { LIVE_GRID_CHANNELS_TO_FETCH } from '../../../constants/liveGrid';

export function getLiveGridNextPageUrl<
  T extends { paging: { URLPage?: string } },
>(
  data: T,
  epgIDs: number[],
  dataPerPage: number = LIVE_GRID_CHANNELS_TO_FETCH
): string | undefined {
  if (!data.paging.URLPage) {
    return;
  }

  return addQueriesParam(data.paging.URLPage, {
    get: String(dataPerPage),
    epgIds: String(epgIDs),
  });
}
