import { ModalV2, ModalV2Size } from '@canalplus/mycanal-sharedcomponent';
import {
  KEY_BACK,
  Layer,
  useActiveLayer,
  useKeyCatcher,
  useStore,
} from '@canalplus/one-navigation';
import { cloneElement, useCallback, useState } from 'react';
import { LAYER_LIVEGRID_MODAL } from '../../../helpers/oneNavigation/layers';

export type LiveGridModalProps = {
  isTvDevice?: boolean;
  triggerModalElement: React.ReactElement;
  modalContent: React.ReactElement;
};

function LiveGridModal({
  isTvDevice = false,
  modalContent,
  triggerModalElement,
}: LiveGridModalProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const openModal = () => setOpenModal(true);

  // Maybe used by modalContent component to trigger refocus when DOM is mounted in async fashion
  const store = useStore();
  const handleFocusable = useCallback(() => store.focusDefault(), [store]);

  useKeyCatcher(KEY_BACK, closeModal, LAYER_LIVEGRID_MODAL);
  useActiveLayer(LAYER_LIVEGRID_MODAL, isModalOpen);

  return (
    <>
      {cloneElement(triggerModalElement, { openModal })}
      {isModalOpen && (
        <ModalV2
          isTvDevice={isTvDevice}
          onClose={() => closeModal()}
          // no size when !isTvDevice, because styles are in conflict with liveGrid styles
          size={isTvDevice ? ModalV2Size.FullScreen : undefined}
          type="liveGrid"
          id="modal-scroll-container"
          hidePadding
        >
          <Layer layer={LAYER_LIVEGRID_MODAL}>
            {cloneElement(modalContent, {
              closeModal,
              onFocusable: handleFocusable,
            })}
          </Layer>
        </ModalV2>
      )}
    </>
  );
}

export default LiveGridModal;
